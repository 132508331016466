// log
import store from "../store";
import axios from "axios";
axios.defaults.baseURL = "https://soco-engineering.com";
axios.defaults.headers.get["Content-Type"] = "application/json;charset=utf-8";
axios.defaults.headers.get["Access-Control-Allow-Origin"] = "*";
const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = (account) => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      let totalSupply = await store
        .getState()
        .blockchain.smartContract.methods.totalSupply()
        .call();
      // それぞれのモードのベースURLを取得
      let modeATokenURI = await store
        .getState()
        .blockchain.smartContract.methods._modeATokenURI()
        .call();
      let modeBTokenURI = await store
        .getState()
        .blockchain.smartContract.methods._modeBTokenURI()
        .call();

      let modeCTokenURI = await store
        .getState()
        .blockchain.smartContract.methods._modeCTokenURI()
        .call();
      // let modeDTokenURI = await store
      //   .getState()
      //   .blockchain.smartContract.methods._modeDTokenURI()
      //   .call();

      // let modeATokenURI = await store
      //   .getState()
      //   .blockchain.smartContract.methods._modeATokenURI()
      //   .call();
      // try {
      //   modeATokenURI = await fetch(`${modeATokenURI}1.json`)
      //     .then((response) => response.json())
      //     .then((data) => {
      //       return data["image"];
      //     });
      // } catch {
      //   modeATokenURI = await fetch(`${modeATokenURI}1.json`)
      //     .then((response) => response.json())
      //     .then((data) => {
      //       return data["image"];
      //     });
      // }
      // let modeBTokenURI = await store
      //   .getState()
      //   .blockchain.smartContract.methods._modeBTokenURI()
      //   .call();
      // try {
      //   modeBTokenURI = await fetch(`${modeBTokenURI}1.json`)
      //     .then((response) => response.json())
      //     .then((data) => {
      //       return data["image"];
      //     });
      // } catch {
      //   modeBTokenURI = await fetch(`${modeBTokenURI}1.json`)
      //     .then((response) => response.json())
      //     .then((data) => {
      //       return data["image"];
      //     });
      // }
      // let modeCTokenURI = await store
      //   .getState()
      //   .blockchain.smartContract.methods._modeCTokenURI()
      //   .call();
      // try {
      //   modeCTokenURI = await fetch(`${modeCTokenURI}1.json`)
      //     .then((response) => response.json())
      //     .then((data) => {
      //       return data["image"];
      //     });
      // } catch {
      //   modeCTokenURI = await fetch(`${modeCTokenURI}1.json`)
      //     .then((response) => response.json())
      //     .then((data) => {
      //       return data["image"];
      //     });
      // }
      // let modeDTokenURI = await store
      //   .getState()
      //   .blockchain.smartContract.methods._modeDTokenURI()
      //   .call();
      // try {
      //   modeDTokenURI = await fetch(`${modeDTokenURI}1.json`)
      //     .then((response) => response.json())
      //     .then((data) => {
      //       return data["image"];
      //     });
      // } catch {
      //   modeDTokenURI = await fetch(`${modeDTokenURI}1.json`)
      //     .then((response) => response.json())
      //     .then((data) => {
      //       return data["image"];
      //     });
      // }
      // let cost = await store
      //   .getState()
      //   .blockchain.smartContract.methods.cost()
      //   .call();

      dispatch(
        fetchDataSuccess({
          totalSupply,
          modeATokenURI,
          modeBTokenURI,
          modeCTokenURI,
          // modeDTokenURI,

          // cost,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
